export {}
const isLocalhost = window.location.origin.indexOf('localhost') > -1
const UAT = 'https://d31euim7r2w3m5.cloudfront.net'

window.HOST_URL = isLocalhost ? '' : '/host'
window.ENTITY_URL = isLocalhost ? `${UAT}/entity` : '/entity'
window.PROFILE_URL = isLocalhost ? `${UAT}/profile` : '/profile'
window.CONTACT_URL = isLocalhost ? `${UAT}/contact` : '/contact'
window.COMMON_URL = isLocalhost ? `${UAT}/common` : '/common'
window.DOCUMENT_URL = isLocalhost ? `${UAT}/document` : '/document'
window.CONNECTION_URL = isLocalhost ? `${UAT}/connection` : '/connection'
window.CATEGORY_URL = isLocalhost ? `${UAT}/category` : '/category'
window.AI_ASSISTANT_URL = isLocalhost ? `${UAT}/ai_assistant` : '/ai_assistant'

import('./bootstrap')
